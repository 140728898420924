import * as React from 'react';
import { Link as GastbyLink } from "gatsby";
import logo from '../../img/logo.png'
import { Box } from '@mui/system';

const Logo = ({ imageProps, title, ...props }) => (
  <GastbyLink to="/" title={title} {...props}>
    <img src={logo} alt="campaign logo" style={{ maxWidth: '150px' }} {...imageProps} />
  </GastbyLink>
)

export default Logo
