import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Hidden } from '@mui/material';
import NavLinks from './NavLinks';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "./Logo";

import NewHamburgerLinks from "./NewHamburgerLinks";

export default function NavBar() {
  const [menuEnabled, setMenuEnabled] = React.useState(false);

  const toggleMenu = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setMenuEnabled(open);
  };


  const handleClick = (event) => {
    setMenuEnabled(!Boolean(menuEnabled));
  };

  const theme = useTheme();
  const disableContainerGutter = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar color="primary" elevation={0} position="static">
      <nav>
      <Container disableGutters={disableContainerGutter}>
        <Box paddingY={1}>
          <Toolbar disableGutters>
            <Hidden mdDown>
              <Box sx={{ flexGrow: 1 }}>
                <NavLinks />
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box sx={{ flexGrow: 1, textAlign: 'center', marginTop: 2, marginBottom: 1 }}>
                <Logo imageProps={{ style: { maxWidth: '150px' }}}/>
              </Box>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
                sx={{ position: 'absolute', right: '10px' }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Box>
        {menuEnabled && (
          <Hidden mdUp>
            <NewHamburgerLinks open={menuEnabled} onClick={toggleMenu} />
          </Hidden>
        )}
      </Container>
      </nav>
    </AppBar>
  );
}
