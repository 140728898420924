import React from "react";
import { Link as GastbyLink } from "gatsby";
import { Box, Divider, Link } from "@mui/material";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Logo from "./Logo";
import DonateButton from "./DonateButton";
import { makeStyles } from '@mui/styles';

import AppContext from '../AppContext'

const navLinkStyles =  {
  fontSize: '1rem',
  fontWeight: 500,
  transition: 'all 1s ease',
  'border-top': '.15rem solid transparent',
  'border-bottom': '.15rem solid transparent',
  '&:hover': {
    'border-bottom': '.15rem solid #FFF',
  }
}

const useStyles = makeStyles({
  navLink: navLinkStyles,
})

export function NavLink(props) {
  const component = props.component || GastbyLink
  const classes = useStyles();


  return (
    <Link
      marginLeft={3}
      marginRight={props.lastElement ? 1 : 0 }
      color="white.main"
      variant="body1"
      underline="none"
      component={component}
      className={classes.navLink}
      {...props}>
      {props.text}
      {props.children}
    </Link>
  );
}

function DropdownLink(props) {
  return (
    <NavLink
      {...props}
      width="100%"
      margin="0"
      textAlign="center"
      sx={{ paddingY: 1, paddingX: 2 }}
      color="primary.main" />
  )
}

export default function NavLinks(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { featureFlags: { endorsementsEnabled, translationPagesEnabled } } = React.useContext(AppContext);


  return (
    <Box
      sx={{ display: 'flex', alignContent: 'flex-end', alignItems: 'center' }}
      paddingTop={3}
      paddingBottom={0}
    >
      <Logo imageProps={{ style: { maxWidth: '175px' }}} style={{ display: 'flex', flexGrow: 1 }}/>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
        <NavLink to="/meet-huge" text="Meet Huge" />
        <NavLink to="/platform" text="Platform" />
        <NavLink to="/press" text="Press" />
        { endorsementsEnabled && (<NavLink to="/endorsements" text="Endorsements" />)}
        <NavLink to="/volunteer" text="Volunteer" lastElement={!translationPagesEnabled} />
        { translationPagesEnabled && (
          <React.Fragment>
            <NavLink
              id="language-menu"
              aria-controls="language-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              component={Link}
              sx={{ display: 'flex' }}
            >
              <LanguageIcon fontSize="small"/>
              <ArrowDropDownIcon fontSize="small"/>
            </NavLink>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem sx={{ padding: 0 }} onClick={handleClose}><DropdownLink to="/languages/zh" text="中文" /></MenuItem>
              <MenuItem sx={{ padding: 0 }} onClick={handleClose}><DropdownLink to="/languages/es" text="Español" /></MenuItem>
              <MenuItem sx={{ padding: 0 }} onClick={handleClose}><DropdownLink to="/languages/bn" text="বাংলা" /></MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </Box>
      <DonateButton blue={false} />
    </Box>
  );
}
