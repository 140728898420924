import React from 'react'
import { Link as GastbyLink } from 'gatsby'

import { Container, Box, Typography, Stack, Grid, IconButton } from '@mui/material'
import Link from "@mui/material/Link";

import Logo from './Logo';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

import AppContext from './../AppContext'

const renderColumn = ({ children, ...params }) => (
  <Grid item xs={12} md={2} {...params}>
    <Box color="third.contrastText" marginTop={5}>
      {children}
    </Box>
  </Grid>
)

const COLUMNS_TRANSLATIONS_ENABLED = {
  xs: 12,
  sm: 8,
  md: 5,
}

const COLUMNS_TRANSLATIONS_DISABLED = {
  xs: 12,
  md: 7,
}

const renderStack = ({ name, list, translationPagesEnabled, ...params }) => (
  renderColumn({
    children: (
      <Stack direction="column" spacing={1} justifyContent="center">
        { name && (
          <Typography fontWeight="bold" sx={{ fontSize: '1rem', textTransform: 'uppercase' }}>
            {name}
          </Typography>
        )}
        {list.map((item) => (
          item
        ))}
      </Stack>
    ),
    ...(translationPagesEnabled ? COLUMNS_TRANSLATIONS_ENABLED : COLUMNS_TRANSLATIONS_DISABLED),
    ...params
  })
)

const StyledLink = ({ children, textTransform, sx, ...params }) => (
  <Typography sx={sx}>
    <Link {...params} color="white.main" underline="none" fontSize="1rem" style={{ textTransform: textTransform || 'uppercase' }}>
      {children}
    </Link>
  </Typography>
)

class Footer extends React.Component {
  render() {
    const {
      committeename,
      actblueurl,
      featureFlags: { translationPagesEnabled, endorsementsEnabled, jobsPageEnabled },
      social: { twitter, instagram, facebook, youtube }
    } = this.context;

    return (<footer style={{ display: 'flex' }}>
      <Box sx={{ backgroundColor: 'third.main', width: '100%' }}>
        <Container>
          {/* <Grid container spacing={{xs: 4, md: 2 }} sx={{ paddingY: 5}}> */}
          <Grid container
            columns={24}
            spacing={{xs: 4}} sx={{ paddingY: 5}}>
            {renderStack({
              name: "Pages",
              translationPagesEnabled,
              list: [
                <StyledLink component={GastbyLink} to="/">
                  Home
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/meet-huge">
                  Meet Huge
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/platform">
                  Platform
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/press">
                  Press
                </StyledLink>,
                  endorsementsEnabled && <StyledLink component={GastbyLink} to="/endorsements">
                    Endorsements
                  </StyledLink>
                ,
              ]
            })}
            {renderStack({
              name: "Other",
              translationPagesEnabled,
              list: [
                <StyledLink component={GastbyLink} to="/volunteer">
                  Volunteer
                </StyledLink>,
                <StyledLink href={actblueurl} target="_blank">
                  Donate
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/media-center">
                  Media Center
                </StyledLink>,
                jobsPageEnabled && <StyledLink component={GastbyLink} to="/jobs">
                  Jobs
                </StyledLink>,
              ]
            })}
            {translationPagesEnabled && renderStack({
              name: "Languages",
              translationPagesEnabled,
              list: [
                <StyledLink component={GastbyLink} to="/languages/zh">
                  中文
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/languages/es">
                  Español
                </StyledLink>,
                <StyledLink component={GastbyLink} to="/languages/bn">
                  বাংলা
                </StyledLink>,
              ],
            })}
            {renderStack({
              list: [
                <Box sx={{ textAlign: 'center', marginBottom: 1 }}>
                  <Logo imageProps={{ style: { width: '200px' }}} />
                </Box>,
                <Typography
                  gutterBottom
                  textAlign="center"
                  padding={1}
                  border="1px solid #FFF">{`Paid for by ${committeename}`}</Typography>,
                <Stack direction="row" spacing={1} justifyContent="center">
                  <IconButton color="white" aria-label="facebook" href={facebook} target="_blank">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton color="white" aria-label="Twitter" href={twitter} target="_blank">
                    <TwitterIcon />
                  </IconButton>
                  <IconButton color="white" aria-label="instagram" href={instagram} target="_blank">
                    <InstagramIcon />
                  </IconButton>
                  <IconButton color="white" aria-label="youtube" href={youtube} target="_blank">
                    <YouTubeIcon />
                  </IconButton>
                </Stack>,
              ],
              xs: 24,
              sm: 24,
              md: 9,
            })}
            {renderStack({
              xs: 24,
              sm: 24,
              md: 24,
              list: [
                <StyledLink
                  textTransform="initial"
                  href="mailto:info@hugeforassembly.com" color="third.contrastText" sx={{ textAlign: 'center' }}
                >
                  info@hugeforassembly.com
                </StyledLink>,
                <Typography variant="overline" textAlign="center">Copyright © 2022. Built by Huge.</Typography>
              ]
            })}
          </Grid>
        </Container>
      </Box>
    </footer>)
  }
}

Footer.contextType = AppContext;

export default Footer;
