import * as React from 'react';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

import AppContext from '../AppContext'

class ButtonGroup extends React.Component {
render() {
  const { onClick, blue,  ...params } = this.props;
  const { actblueurl } = this.context;

  console.log(this.props)

  const color = blue ? 'primary' : 'secondary'

    return (
      <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', ...params?.sx }} {...params}>
        <Button
          href={actblueurl}
          target="_blank"
          rel="noopener noreferrer"
          color={color}
          variant="contained"
          onClick={onClick}
          disableElevation
        >
          Donate
        </Button>
      </Stack>
    )
  }
}

ButtonGroup.contextType = AppContext

export default ButtonGroup
