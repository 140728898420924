import { graphql, useStaticQuery } from 'gatsby'

const useSettings = () => {
  const result = useStaticQuery(
    graphql`
      query SETTINGS_QUERY {
        markdownRemark(frontmatter: {templateKey: {eq: "settings-page"}}) {
          frontmatter {
            sitename
            actblueurl
            committeename
            featureFlags {
              endorsementsEnabled
              translationPagesEnabled
              jobsPageEnabled
            }
            social {
              twitter
              instagram
              facebook
              youtube
            }
            topbanner {
              text
              url
              enabled
              cachekey
            }
          }
        }
      }
    `
  )

  return result
}

export default useSettings
