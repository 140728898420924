import React from "react";
import { Link } from "gatsby";
import { Box } from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@mui/icons-material/Close';

import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ButtonGroup from "./ButtonGroup";

import AppContext from '../AppContext'


const ListButtonLink = ({ text, url, onClick }) => (
  <ListItemButton
    key={text}
    to={url}
    component={Link}
    onClick={onClick(false)}
    sx={{ paddingY: 1.5 }}
>
    <ListItemText primary={text} />
  </ListItemButton>
)


export default function NewHamburgerLinks({ open, onClick }) {
  const { featureFlags: { endorsementsEnabled, translationPagesEnabled } } = React.useContext(AppContext);

  const links = [
    { url: "/", text: "Home" },
    { url: "/meet-huge", text: "Meet Huge" },
    { url: "/platform", text: "Platform" },
    { url: "/press", text: "Press" },
    { url: "/endorsements", text: "Endorsements", disabled: !endorsementsEnabled },
    { url: "/volunteer", text: "Volunteer" },
  ]

  const otherLinks = [
    { url: "/languages/zh", text: "中文" },
    { url: "/languages/es", text: "Español" },
    { url: "/languages/bn", text: "বাংলা" },
  ]


  const list = () => (
    <Box
      sx={{ width: '320px' }}
      role="presentation"
      onKeyDown={onClick(false)}
    >
      <List>
        <ListItem>
          <Button size="small" onClick={onClick(false)} startIcon={<CloseIcon />}>
            Close
          </Button>
        </ListItem>
        <Divider />
        {links.filter(({ disabled }) => !disabled).map(({ url, text }) => (
          <ListButtonLink {...{ url, text, onClick }} />
        ))}
        <Divider />
      </List>
      <ButtonGroup onClick={onClick(false)} blue={true} />
      {translationPagesEnabled && (
        <List>
          <Divider />
          {otherLinks.map(({ url, text }) => (
            <ListButtonLink {...{ url, text, onClick }} />
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClick(false)}
        onOpen={onClick(true)}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
