import * as React from 'react';
import { useContext } from 'react';
import { Alert, Link } from '@mui/material';
import AppContext from '../AppContext';

export default function Banner({ }) {
  const { topbanner: { url, text, enabled, cachekey }} = useContext(AppContext);
  const fullCacheKey = `${text}-${cachekey}`
  const [hideBanner, setHideBanner] = React.useState(typeof(localStorage) !== 'undefined' && localStorage.getItem(fullCacheKey) === 'true');

  const onClose = (e) => {
    if (typeof(localStorage) !== 'undefined') {
      localStorage.setItem(fullCacheKey, true)
    }
    setHideBanner(true)
  }

  const showBanner = enabled && !hideBanner

  return showBanner && (
    <Alert
      onClose={() => { onClose() }}
      icon={false}
      sx={{
        '.MuiAlert-action': {
          alignItems: 'center',
          padding: '.25rem 1rem .25rem 0',
          marginRight: 0,
          color: 'primary.contrastText'
        },
        '.MuiAlert-message': {
          paddingLeft: '1rem',
          flexGrow: 1,
          textAlign: 'center',
        },
        '.MuiSvgIcon-root': {
          height: '1rem',
          width: '1rem',
        },
        borderRadius: 0,
        backgroundColor: 'primary.dark',
        textTransform: 'uppercase',
        fontSize: '.85rem',
        padding: 0,
      }} >
      <Link href={url}
        target="_blank"
        sx={{ padding: '.25rem', display: 'block', color: 'primary.contrastText', textDecoration: 'none',
          letterSpacing: '0.25px'
        }}
        fontWeight="fontWeightBold"
        >
        {text}
      </Link>
    </Alert>
  );
}
