import * as React from 'react';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

import AppContext from '../AppContext'

class DonateButton extends React.Component {
  render() {
    const { onClick, blue,  ...params } = this.props;
    const { actblueurl } = this.context;

    const color = blue ? 'primary' : 'secondary'

    return (
      <Button
        href={actblueurl}
        target="_blank"
        rel="noopener noreferrer"
        color={color}
        variant="contained"
        onClick={onClick}
        disableElevation
        sx={{ height: 'inital'}}
      >
        Donate
      </Button>
    )
  }
}

DonateButton.contextType = AppContext

export default DonateButton
