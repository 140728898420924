import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/layout/Footer'
import Navbar from '../components/layout/Navbar'
import './all.sass'
import CssBaseline from '@mui/material/CssBaseline';
import useSiteMetadata from './SiteMetadata'
import fetchSettings from './Settings'
import { withPrefix } from 'gatsby'

import { THEME } from '../constants/theme'
import { ThemeProvider } from "@mui/material/styles";
import TopBanner from './layout/TopBanner'

import AppContext from './AppContext'

const TemplateWrapper = ({ children }) => {
  const { markdownRemark: { frontmatter: settings } } = fetchSettings()

  return (
    <AppContext.Provider value={settings}>
      <ThemeProvider theme={THEME}>
        <CssBaseline />
        <div>
          <Helmet>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`${withPrefix('/')}img/apple-touch-icon.png`}
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix('/')}img/favicon_32x32.ico`}
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix('/')}img/favicon_16x16.ico`}
              sizes="16x16"
            />

            <link
              rel="mask-icon"
              href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
              color="#ff4400"
            />
            <meta name="theme-color" content="#fff" />
          </Helmet>
          <TopBanner />
          <Navbar />
          <div>{children}</div>
          <Footer />
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default TemplateWrapper
