import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { grey } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      main: "#39C3FF",
      light: '#39C3FF',
      dark: '#0c84b7',
      // contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#19aceb",
    },
    white: {
      main: "#FFFFFF",
      dark: "#FFFFFF",
      light: "#FFFFFF",
    },
    third: {
      main: "#000000",
      contrastText: "#FFF",
    },
    primaryLight: {
      main: "#000",
      dark: "#000",
      light: "#000",
    },
    contrastThreshold: 2,
  },
  typography: {
    fontSize: 16,
    fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    h1: {
      fontFamily: `'Space Grotesk', sans-serif`,
      fontWeight: 700,
    },
    h2: {
      fontFamily: `'Space Grotesk', sans-serif`,
      fontWeight: 700,
    },
    h3: {
      fontFamily: `'Space Grotesk', sans-serif`,
      fontWeight: 700,
    },
    h4: {
      fontFamily: `'Space Grotesk', sans-serif`,
      fontWeight: 700,
    },
    h5: {
      fontFamily: `'Space Grotesk', sans-serif`,
      fontWeight: 700,
    },
    overline: {
      textTransform: 'uppercase',
      fontSize: '.85rem',
      lineHeight: 1
    }
  }
})

export const MARKDOWN_STYLES = {
  textRendering: 'optimizeLegibility',
  fontFamily: `'IBM Plex Sans', 'sans-serif'`,
  fontSize: '1.1rem',
  fontWeight: 400,
  lineHeight: 1.5,
  '@media(max-width: 600px)' : {
    fontSize: '1.0714285714285714rem',
  },
  '& h2, h3, h4': {
    marginTop: 0,
    fontFamily: `'Space Grotesk', 'sans-serif'`,
  },
  a: {
    color: "primary.main",
    '&:visited': {
      color: "primary.dark"
    }
  }
}

export const THEME = responsiveFontSizes(theme);
